import styles from './HeroSlider.module.scss';

import { Fragment } from 'react';
import typeSlider, { typeSlideFeatures } from '@/utilities/types/Slider';
import Button from '@shared/components/atoms/Button';
import cn from 'classnames';
import Image from 'next/image';
import typeImage from '@shared/utilities/types/Image';
import { SlideCaption } from '@/components/molecules/Slider/SliderCaption';

interface SliderStaticImageProps {
  content: {
    image?: typeImage;
    features?: typeSlideFeatures[];
    caption?: typeSlider['slides'][number]['caption']
  };
}

export const SliderStaticImage = (props: SliderStaticImageProps) => {
  const { content } = props;

  return (
    <div className={styles.staticImage}>
      {content.image && (
        <div className={styles.imageWrapper}>
          <Image
            alt={content.image.altText}
            className={styles.image}
            height={content.image.mediaDetails.height || 680}
            src={content.image.mediaItemUrl}
            width={content.image.mediaDetails.width || 1728}
            priority
          />
        </div>
      )}
      {content.features &&
        content.features?.map((feature, featureIndex: number) => (
          <Fragment key={featureIndex}>
            <style jsx>{` div {
                    ${
                      feature.positioning?.desktop?.left ||
                      feature.positioning?.desktop?.right ||
                      feature.positioning?.desktop?.top
                        ? `
                            ${
                              feature.positioning?.desktop?.left
                                ? `left: ${feature.positioning.desktop.left}rem;`
                                : ''
                            }
                            ${
                              feature.positioning?.desktop?.top
                                ? `top: ${feature.positioning.desktop.top}rem;`
                                : ''
                            }
                            ${
                              feature.positioning?.desktop?.right
                                ? `right: ${feature.positioning.desktop.right}rem;`
                                : ''
                            }
                        `
                        : ''
                    }
              `}</style>
            <div
              className={cn(
                styles.feature,
                feature.imageLocation === 'above' && styles.imageAbove,
                `feature${featureIndex + 1}`,
              )}
            >
              {feature.button && feature.button.url && (
                <Button
                  style="primary"
                  size="large"
                  href={feature.button.url}
                  className={`${
                    feature.button.icon &&
                    feature.button.icon.mediaItemUrl &&
                    feature.button.iconLocation === 'right'
                      ? styles.iconRight
                      : ''
                  }`}
                >
                  {feature.button.icon && feature.button.icon.mediaItemUrl && (
                    <i>
                      <Image
                        src={feature.button.icon.mediaItemUrl}
                        alt={feature.button.icon.altText}
                        width={feature.button.icon.mediaDetails.width || 15}
                        height={feature.button.icon.mediaDetails.height || 15}
                      />
                    </i>
                  )}
                  {feature.button.text}
                </Button>
              )}
              {feature.image && feature.image.mediaItemUrl && (
                <Image
                  src={feature.image.mediaItemUrl}
                  alt={feature.image.altText || 'feature'}
                  width={feature.image.mediaDetails.width || 300}
                  height={feature.image.mediaDetails.height || 450}
                />
              )}
            </div>
          </Fragment>
        ))}
        {content.caption &&
          <div className={styles.captionWrapper}>
            <SlideCaption caption={content.caption} />
          </div>
        }
    </div>
  );
};
